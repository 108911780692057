import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {jwtDecode} from "jwt-decode"; // Fixed the import for jwtDecode

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [rmId, setRmId] = useState(null);
  const [userId, setUserId] = useState(null); // Add userId
  const [userName, setUserName] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken.exp * 1000 > Date.now()) {
          setIsAuthenticated(true);
          setUserRole(decodedToken.role);
          setRmId(decodedToken.rm_id || null);
          setUserId(decodedToken.user_id || null); // Extract userId
          setUserName(decodedToken.name || "User");
        } else {
          localStorage.removeItem("token");
          sessionStorage.removeItem("token");
        }
      } catch (error) {
        console.error("Token parsing error:", error);
        localStorage.removeItem("token");
        sessionStorage.removeItem("token");
      }
    }
  }, []);

  const login = (token, rememberMe) => {
    const decodedToken = jwtDecode(token);
    if (rememberMe) {
      localStorage.setItem("token", token);
    } else {
      sessionStorage.setItem("token", token);
    }
    setIsAuthenticated(true);
    setUserRole(decodedToken.role);
    setRmId(decodedToken.rm_id || null);
    setUserId(decodedToken.user_id || null); // Set userId
    console.log(userId);
    
    setUserName(decodedToken.name || "User");
    navigate("/dashboard");
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUserRole(null);
    setRmId(null);
    setUserId(null); // Clear userId
    setUserName(null);
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
    navigate("/signin");
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        userRole,
        rmId,
        userId, // Provide userId in context
        userName,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};


export const useAuth = () => {
  return useContext(AuthContext);
};
